import Chart from "react-apexcharts";
import {
  barChartColor,
  parsePercentage,
  colorAmarillo1,
  colorRojoClaro1,
  colorVerdeAgua2,
} from "../../utils";
import { Flex } from "@mantine/core";
import { useTranslation } from "react-i18next";

type Props = {
  height?: number;
  width?: number;
  colors?: string[];
  responsive?: any[];
  title?: string;
  xaxisTitle?: string;
  tooltipEnabled?: boolean;
  max?: number;
  categories: any[];
  tickAmount?: number;
  seriesData: number[][];
  seriesNames?: string[];
  minimumValue?: number;
  fontSize?: number;
  horizontal?: boolean;
  distributed?: boolean;
  goals?: number[][];
  goalNames?: string[];
  strokeWidth?: number;
  justify?: string;
};

export const BarChart = ({
  height,
  width,
  colors = [barChartColor],
  responsive = [
    {
      breakpoint: 700,
      options: {
        chart: {
          width: 300,
        },
      },
    },
  ],
  title = "",
  tooltipEnabled = true,
  max = undefined,
  categories,
  tickAmount = 5,
  seriesData,
  xaxisTitle = undefined,
  seriesNames = [],
  minimumValue = 0.1,
  fontSize = 12,
  horizontal = true,
  distributed = false,
  goals,
  goalNames,
  strokeWidth,
  justify = "center",
}: Props) => {
  const { t } = useTranslation();
  if (xaxisTitle === undefined) {
    xaxisTitle = t("percentage") + " %";
    if (seriesNames.length === 0)
      seriesNames = [xaxisTitle];
  }
  const dataLabelsFormatter = (
    val: number,
    { seriesIndex, dataPointIndex, w }: any
  ) => {
    const originalValue = seriesData[seriesIndex][dataPointIndex];
    if (originalValue >= 1.5) return "> 150%";
    return `${parsePercentage(originalValue)}%`;
  };

  const tooltipFormatter = (
    val: number,
    { seriesIndex, dataPointIndex, w }: any
  ) => {
    // const goals = w.config.series[seriesIndex].data[dataPointIndex].goals;
    // if (goals && goals.length && typeof val === "number") {
    //   return `${parsePercentage(goals[0].value)}%`;
    // }

    const originalValue = seriesData[seriesIndex][dataPointIndex];
    return `${parsePercentage(originalValue)}%`;
  };

  const parseBarChartValue = (value: number) => {
    if (!value) return 0;
    if (value < minimumValue) return parsePercentage(minimumValue);
    if (value >= 1.5) return 150;
    return parsePercentage(value);
  };

  const goalColors = [colorRojoClaro1, colorVerdeAgua2, colorAmarillo1];
  // const goalColors =
  //   user.hasCountryBenchmark && user.hasRegionalBenchmark
  //     ? [colorRojoClaro1, colorVerdeAgua2]
  //     : user.hasRegionalBenchmark
  //     ? [colorRojoClaro1]
  //     : [colorVerdeAgua2];

  const getData = (data: number[]) => {
    if (!goals || !goalNames) {
      return data.map((value) => parseBarChartValue(value));
    } else {
      const newData = data.map((value, dataIndex) => ({
        y: parseBarChartValue(value),
        x: "",
        goals: goals.map((goal, goalIndex) => ({
          name: goalNames[goalIndex],
          value: parseBarChartValue(goal[dataIndex]),
          strokeWidth: goalNames[goalIndex] === "Medición anterior" ? 5 : 13,
          strokeHeight: goalNames[goalIndex] === "Medición anterior" ? 13 : 0,
          strokeLineCap:
            goalNames[goalIndex] === "Medición anterior" ? "" : "round",
          strokeColor: goalColors[goalIndex],
        })),
      }));
      return newData;
    }
  };

  return (
    <Flex justify={justify}>
      <Chart
        type="bar"
        height={height}
        width={width}
        options={{
          chart: {
            type: "bar",
          },
          tooltip: {
            enabled: tooltipEnabled,
            y: {
              formatter: goals ? undefined : tooltipFormatter,
            },
            style: {
              fontSize: `${fontSize}px`,
            },
          },
          colors: goals ? [...colors, ...goalColors] : [...colors],
          plotOptions: {
            bar: {
              horizontal: horizontal,
              distributed: distributed,
            },
          },
          responsive,
          title: {
            text: title,
          },
          xaxis: {
            title: {
              text: xaxisTitle,
              style: {
                fontSize: `${fontSize}px`,
              },
            },
            max,
            categories,
            tickAmount,
            labels: {
              style: {
                fontSize: `${fontSize}px`,
              },
            },
          },
          yaxis: {
            labels: {
              style: {
                fontSize: `${fontSize}px`,
              },
            },
          },
          dataLabels: {
            enabled: true,
            formatter: dataLabelsFormatter,
            style: {
              fontSize: `${fontSize}px`,
            },
          },
          ...(goalNames && goals
            ? {
                legend: {
                  fontSize: `${fontSize}px`,
                  showForSingleSeries: goals !== undefined,
                  customLegendItems: [seriesNames[0], ...goalNames],
                },
              }
            : {}),
          ...(strokeWidth
            ? {
                stroke: {
                  colors: ["transparent"],
                  width: strokeWidth,
                },
              }
            : {}),
        }}
        series={seriesData.map((data, index) => ({
          name: seriesNames[index],
          data: getData(data),
        }))}
      />
    </Flex>
  );
};
